import { Agent } from '../models/Agent';

export interface Search {
    pageResults: Agent[];
    searchFailed?: boolean;
    searchInProgress?: boolean;
    currentPage?: number;
    totalNumberOfPages?: number;
    totalNumberOfAgents?: number;
    sorting?: Sorting;
    alternativeResults?: AlternativeResults;
}

export interface Sorting {
    sortBy: string;
    order: string;
}

export interface AlternativeResults {
    nearbyAgents: Agent[];
    term: string;
    geoPathUri: string;
}

export interface GacOverAllInfo {
    suggestionGroups: Array<GacSuggestionGroup>;
    selectedSuggestion?: GacSuggestionItem;
    suggestionInputError?: boolean;
}

export interface GacSuggestionGroup {
    title: string;
    items: Array<GacSuggestionItem>;
}

export interface GacSuggestionItem {
    name: string;
    value: string;
    geoPath: GeoPath;
    type: string;
    seoLabel?: string;
}

export interface GeoPath {
    uri: string;
    realNames?: string[];
}

export type Config = Record<string, unknown>;
export interface Tracking {
    searchId: string | null;
}
export interface SeoData {
    neighbourRegions: LinkItem[];
    childrenRegions: LinkItem[];
    neighbourRegionType: string;
    childrenRegionType: string;
}

export interface BranchenbuchSearch {
    isBranchenbuchSearch: boolean;
}

export interface State {
    search: Search;
    config: Config;
    gacOverAllInfo: GacOverAllInfo;
    tracking: Tracking;
    urlParams?: {
        urlUpdated: boolean;
    };
    seoData: SeoData;
    branchenbuchSearch: BranchenbuchSearch;
    teaserData: TeaserData;
    dynamicFaq: DynamicFaq;
}
export interface Breadcrumbs {
    city: string;
    cityLink: string;
    district: string;
}

export interface TeaserData {
    isLoggedIn: boolean;
    staticTeaserData: StaticTeaserData;
}

export interface StaticTeaserData {
    logoUrl: string;
    brandColor?: string;
    displayName: string;
    message: string;
    linkDescription: string;
    linkUrl: string;
    agentPictureUrl: string;
}

export interface DynamicFaqPosition {
    displayName: string;
    displayPosition: string;
    schaufensterPlacements: number;
    premiumPlacements: number;
    positionNumber: number;
    visibilityIncreasePercentage: number;
}

export interface DynamicFaq {
    sovEnabled: boolean;
    logoUrl: string;
    displayName: string;
    numberOfRegions: number;
    currentPosition?: number;
    currentPageNumber?: number;
    selectedSuggestion?: GacSuggestionItem;
    positionList: DynamicFaqPosition[];
    selectedPosition?: number;
}

const search: Search = {
    pageResults: [],
    searchFailed: false,
    currentPage: 1,
    totalNumberOfPages: 1,
    totalNumberOfAgents: 0,
};

export const teaserData: TeaserData = {
    isLoggedIn: false,
    staticTeaserData: {
        logoUrl: '',
        brandColor: '',
        displayName: '',
        message: '',
        linkDescription: '',
        linkUrl: '',
        agentPictureUrl: '',
    },
};

export const dynamicFaq: DynamicFaq = {
    sovEnabled: false,
    logoUrl: '',
    displayName: '',
    numberOfRegions: 0,
    positionList: [],
    selectedPosition: 0,
};

const config: Config = {};
const gacOverAllInfo: GacOverAllInfo = {
    suggestionGroups: [],
    selectedSuggestion: { name: '', value: '', geoPath: { uri: '', realNames: [] }, type: '', seoLabel: '' },
};
const tracking: Tracking = {
    searchId: null,
};
const seoData: SeoData = { neighbourRegions: [], childrenRegions: [], childrenRegionType: '', neighbourRegionType: '' };

const branchenbuchSearch: BranchenbuchSearch = {
    isBranchenbuchSearch: false,
};

const initialState: State = {
    search,
    config,
    gacOverAllInfo: gacOverAllInfo,
    tracking,
    seoData,
    branchenbuchSearch,
    teaserData,
    dynamicFaq,
};

export type LinkItem = {
    name: string;
    link: string;
};
export default initialState;
