import React from 'react';
import ConnectedHomePageContents from '../../components/homePageContents/ConnectedHomePageContents';

import pageStyles from '../../index.less';
import ConnectedHeroBlock from '../../components/heroblock/ConnectedHeroBlock';

const Home: React.FC = (): JSX.Element => {
    return (
        <div className={`${pageStyles.mainPageStyle} padding-none`}>
            <ConnectedHeroBlock />
            <ConnectedHomePageContents />
        </div>
    );
};

export default Home;
