import initialState, { DynamicFaq } from '../store/initialState';
import { SET_SELECTED_POSITION } from '../actions/dynamicFaq.types';
import { DynamicFaqAction } from '../actions/dynamicFaq';

export default (state = initialState.dynamicFaq, action: DynamicFaqAction): DynamicFaq => {
    switch (action.type) {
        case SET_SELECTED_POSITION: {
            return {
                ...state,
                selectedPosition: action.selectedPosition,
            };
        }
        default:
            return state;
    }
};
