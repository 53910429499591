import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

export function fetchCmsContent(): string {
    const [cmsContent, setCmsContent] = useState<string>('');
    const store = useStore();

    useEffect(() => {
        async function downloadContent() {
            setCmsContent((await downloadCmsContent(store.getState().config.API_ENDPOINT)) || '');
        }
        downloadContent();
    }, []);
    return cmsContent;
}
async function downloadCmsContent(apiEndPoint: string): Promise<string | undefined> {
    const response = await fetch(
        `https://${window.location.host}/content/campaigns/IS24Branchenbuch/maklersuche-snippets/content/_jcr_content/par.html`,
    );
    if (response.ok) {
        return await response.text();
    }
    return '<div>cms content here</div>';
}
