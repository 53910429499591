let trackingQueue: TrackingEvent[] = [];

export function trackEvent(event: TrackingEvent): void {
  trackingQueue.push(event);

  if (typeof window === 'undefined' || !window['IS24'] || !window['IS24'].TEALIUM || !window['IS24'].TEALIUM.tracking) {
    return;
  }

  trackingQueue.forEach((eventFromQueue) => {
    const {
      label,
      category = 'residential',
      action = 'business_directory',
      product = 'business_directory',
      name,
      parameter_1,
      parameter_2,
    } = eventFromQueue;

    window['IS24'].TEALIUM.tracking.report({
      evt_ga_category: category,
      evt_ga_action: action,
      evt_ga_label: label,
      event_product: product,
      event_name: name,
      event_parameter_1: parameter_1,
      event_parameter_2: parameter_2,
    });
  });
  trackingQueue = [];
}

export interface TrackingEvent {
  label: string;
  category?: string;
  action?: string;
  product?: string;
  name?: string;
  parameter_1?: string;
  parameter_2?: string;
}
