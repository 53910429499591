import React, { useEffect, useState, useRef } from 'react';
import styles from './GacContainer.less';
import dropdownStyles from '../realtorCategoryDropdown/dropdown.less';
import { GacInputField } from '../gacinput/GacInputField';
import { GacSuggestionItem } from '../../store/initialState';
import { CategoryDropdown, RealtorCategory } from '../realtorCategoryDropdown/CategoryDropdown';

type Props = {
    apiEndPoint: string;
    buttonText: string;
    id: string;
    placeholder: string;
    onButtonClick: () => void;
    onSuggestionSelected: (suggestionItem: GacSuggestionItem) => void;
    nextgenData?: boolean;
    isBranchenbuchSearch: boolean;
    onCategorySelected: (category: RealtorCategory) => void;
    selectedCategory: RealtorCategory;
};
const GacContainer: React.FC<Props> = ({
    id,
    apiEndPoint,
    buttonText,
    placeholder,
    onButtonClick,
    onSuggestionSelected,
    nextgenData = true,
    isBranchenbuchSearch,
    onCategorySelected,
    selectedCategory,
}: Props) => {
    const [expandedView, expandSearchBlock] = useState(false);
    const [fakeInputValue, setFakeInputValue] = useState('');
    const fakeInputRef = useRef<HTMLInputElement>();
    const gacInputRef = useRef<GacInputField>();
    const expandedStyle = expandedView ? styles.expanded : '';

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        if (expandedView) {
            body.classList.add(`${styles.overlayOpened}`);
        } else {
            body.classList.remove(`${styles.overlayOpened}`);
            setFakeInputValue(gacInputRef.current?.getValue() || '');
            setTimeout(() => {
                const input = fakeInputRef.current as HTMLInputElement;
                input.style.transform = 'none';
            }, 600);
        }
    }, [expandedView]);
    useEffect(() => {
        const handler = (e: TouchEvent) => {
            e.preventDefault();
            const input = fakeInputRef.current as HTMLInputElement;
            input.style.transform = 'translateY(-10000px)';
            expandSearchBlock(true);
            setTimeout(() => {
                gacInputRef.current?.focus();
            }, 650);
        };
        fakeInputRef.current?.addEventListener('touchstart', handler);
        fakeInputRef.current?.addEventListener('focus', handler);
        return () => fakeInputRef.current?.removeEventListener('touchstart', handler);
    }, []);

    return (
        <div
            className={`grid grid-flex one-whole padding-m margin-left-none background-white ${styles.searchContainer} ${expandedStyle}`}
        >
            <div className={`one-whole background-white padding-xl ${styles.hiddenBlock} ${expandedStyle}`}>
                <button
                    onClick={onButtonClick}
                    className={`button button-primary ${styles.hiddenButton} ${expandedStyle} ${
                        isBranchenbuchSearch ? styles.branchenbuch : ''
                    }`}
                    type="submit"
                    data-test-id="makler_finden_overlay"
                >
                    {buttonText}
                </button>
            </div>
            <button
                className={`button-icon-standalone padding-none ${styles.closeButton} ${expandedStyle}`}
                onClick={() => expandSearchBlock(false)}
            >
                <span className="is24-icon-closing font-regular" />
            </button>
            <div
                className={`grid-item input-text-container ${styles.searchBar} ${expandedStyle} ${
                    isBranchenbuchSearch ? styles.branchenbuch : ''
                }`}
            >
                <CategoryDropdown
                    hidden={!isBranchenbuchSearch}
                    onCategorySelected={onCategorySelected}
                    selectedCategory={selectedCategory}
                    displayClass={expandedView ? dropdownStyles.visible : 'desk-hide lap-hide'}
                />
                <GacInputField
                    id={id}
                    ref={gacInputRef as any}
                    apiEndPoint={apiEndPoint}
                    onSuggestionSelected={onSuggestionSelected}
                    keepFocus
                    placeholder={placeholder}
                    nextgenData={nextgenData}
                />

                <input
                    ref={fakeInputRef as any}
                    type="text"
                    className={`input-text ${styles.searchInputFake}`}
                    placeholder={placeholder}
                    value={fakeInputValue}
                    readOnly
                />
            </div>

            <div className="grid-item palm-hide padding-left-m grid grid-flex">
                <CategoryDropdown
                    hidden={!isBranchenbuchSearch}
                    onCategorySelected={onCategorySelected}
                    selectedCategory={selectedCategory}
                />
                <button
                    onClick={onButtonClick}
                    className="button button-primary"
                    data-test-id="makler_finden_desk"
                    type="submit"
                >
                    {buttonText}
                </button>
            </div>
            <div className="grid-item desk-hide lap-hide padding-left-m">
                <button
                    onClick={onButtonClick}
                    className="button button-primary button-icon-standalone-medium"
                    type="submit"
                    data-test-id="makler_finden_mobile"
                >
                    <span className="is24-icon-search" />
                </button>
            </div>
        </div>
    );
};

export default GacContainer;
