import React from 'react';

const closeDropdown = (wrapperRef: React.RefObject<HTMLElement>, setShowDropdown: (boolean) => void) => {
    const handleDropdown = (showDropDown) => {
        const hideDropDownOnScroll = () => {
            setShowDropdown(false);
        };
        const hideDropDownOnClickOutside = (event: Event) => {
            if (showDropDown && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        window.addEventListener('scroll', hideDropDownOnScroll);
        document.addEventListener('click', hideDropDownOnClickOutside);

        const unsubscribe = () => {
            window.removeEventListener('scroll', hideDropDownOnScroll);
            document.removeEventListener('click', hideDropDownOnClickOutside);
        };
        if (!showDropDown) {
            return unsubscribe();
        }
        return () => {
            unsubscribe();
        };
    };

    return { handleDropdown };
};

export default closeDropdown;
