import initialState from '../store/initialState';
import State from '../store/initialState';
import { SET_AGENT_TEASER } from '../actions/agentTeaser.types';
import { AgentTeaserAction } from '../actions/agentTeaser';

export default (state = initialState.teaserData, action: AgentTeaserAction): typeof State.teaserData => {
    switch (action.type) {
        case SET_AGENT_TEASER: {
            return {
                ...state,
                ...action.teaserData,
            };
        }
        default:
            return state;
    }
};
