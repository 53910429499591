import initialState from '../store/initialState';
import State from '../store/initialState';
import { SET_BRANCHENBUCH_SEARCH } from '../actions/branchenbuchSearch.types';
import { BranchenbuchSearchAction } from '../actions/branchenbuchSearch';

export default (
    state = initialState.branchenbuchSearch,
    action: BranchenbuchSearchAction,
): typeof State.branchenbuchSearch => {
    switch (action.type) {
        case SET_BRANCHENBUCH_SEARCH: {
            return {
                ...state,
                ...action.branchenbuchSearch,
            };
        }
        default:
            return state;
    }
};
