import React from 'react';

type Props = {
    children?: React.ReactNode;
};
const TabContainer: React.FC<Props> = ({ children }: Props) => {
    return (
        <div className="tabs-container">
            <div className="tab-list" role="tablist" aria-label="tab list">
                {children}
            </div>
        </div>
    );
};

export default TabContainer;
