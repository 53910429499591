import React, { useEffect, useRef, useState } from 'react';
import styles from './dropdown.less';
import closeDropdown from '../common/closeDropdown';

type Props = {
    selectedCategory: RealtorCategory;
    onCategorySelected: (category: RealtorCategory) => void;
    displayClass?: string;
    hidden: boolean;
};
export const CategoryDropdown = ({
    selectedCategory,
    onCategorySelected,
    displayClass,
    hidden,
}: Props): JSX.Element | null => {
    const [showDropDown, setShowDropdown] = useState(false);
    const sortDropDownRef = useRef<HTMLDivElement>(null);
    const { handleDropdown } = closeDropdown(sortDropDownRef, setShowDropdown);

    if (hidden) {
        return null;
    }
    useEffect(() => {
        return handleDropdown(showDropDown);
    }, [showDropDown]);

    const toggleDropdown = (event?) => {
        event?.preventDefault();
        setShowDropdown(!showDropDown);
    };

    const onSelected = (category) => {
        toggleDropdown();
        onCategorySelected(category);
    };

    const displayedLabel = (label: string) => {
        if (!label.includes('/')) {
            return label;
        }
        if (label.includes('/')) {
            return label.split('/').map((part, index, array) => (
                <React.Fragment key={index}>
                    {part}
                    {index !== array.length - 1 && '/'}
                    &#x200b;
                </React.Fragment>
            ));
        }
    };

    return (
        <div ref={sortDropDownRef} className={`grid-item margin-right-m ${displayClass} ${styles.container}`}>
            <button
                onClick={toggleDropdown}
                className={`select absolute-reference font-s ${styles.selectButton}`}
                data-test-id="categories-button"
            >
                <span className={styles.label}>{selectedCategory.label}</span>
                <span className={`is24-icon-chevron-down ${styles.chevron}`} />
            </button>
            {showDropDown && (
                <div className={styles.dropdown}>
                    {realtorCategories.map((option) => (
                        <div
                            key={option.value}
                            className={`${styles.entry} ${selectedCategory === option ? styles.selected : ''}`}
                            onClick={() => onSelected(option)}
                            data-test-id="realtor-category"
                        >
                            {displayedLabel(option.label)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export type RealtorCategory = {
    label: string;
    value: string;
};

export const realtorCategories: Array<RealtorCategory> = [
    {
        label: 'Immobilienanbieter',
        value: 'ANBIETER',
    },
    {
        label: 'Gewerbemakler',
        value: 'GEWERBEMAKLER',
    },
    {
        label: 'Bauträger',
        value: 'BAUTRAGER',
    },
    {
        label: 'Fertighaus/Massivhaushersteller',
        value: 'FERTIGHAUS',
    },
    {
        label: 'Wohnungsunternehmen',
        value: 'WOHNUNGSUNTERNEHMEN',
    },
    {
        label: 'Hausverwaltung',
        value: 'VERWALTUNG',
    },
    {
        label: 'Umzugsunternehmen',
        value: 'UMZUG',
    },
    {
        label: 'Finanzierungsanbieter',
        value: 'BAUFI',
    },
];
