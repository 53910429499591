import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import geoSuggestionReducer from './geoSuggestionReducer';
import seoDataReducer from './seoDataReducer';
import trackingReducer from './trackingReducer';
import branchenbuchReducer from './branchenbuchReducer';
import agentTeaserReducer from './agentTeaserReducer';
import dynamicFaqReducer from './dynamicFaqReducer';

export default combineReducers({
    search: searchReducer,
    config: (state = {}) => state,
    gacOverAllInfo: geoSuggestionReducer,
    tracking: trackingReducer,
    seoData: seoDataReducer,
    branchenbuchSearch: branchenbuchReducer,
    teaserData: agentTeaserReducer,
    dynamicFaq: dynamicFaqReducer,
});
