export function generateSearchId(time: Date = new Date()): string {
    const radix = 36;
    // consists of a timed prefix appended with a random string
    return (
        (
            (time.getUTCMinutes() * 100_000_000 + time.getUTCHours() * 1_000_000 + time.getUTCFullYear() - 2000) *
                10_000 +
            (time.getUTCMonth() + 1) * 100 +
            time.getUTCDate()
        ).toString(radix) + Math.floor(Math.random() * 100000).toString(radix)
    );
}
