import React from 'react';
import { useStore } from 'react-redux';

import homePageContentCMS from '../../../../cms/homePage.html';
import style from './HomePageContents.less';
import { fetchCmsContent } from '../../hooks/fetchCmsContent';
import homePageBnbContentCMS from '../../../../cms/homePageBnb.html';

type HomePageContents = {
    isBranchenbuch: boolean;
};

export const HomePageContents: React.FC<HomePageContents> = ({ isBranchenbuch }: HomePageContents): JSX.Element => {
    const cmsContent = fetchCmsContent();
    const store = useStore();
    const isSandbox = store?.getState()?.config?.API_ENDPOINT?.includes('tuv');
    return (
        <div className={`one-whole horizontal-center padding-l ${style.contentWrapper}`}>
            {isSandbox && cmsContent !== '' ? (
                <div dangerouslySetInnerHTML={{ __html: cmsContent }} />
            ) : isBranchenbuch ? (
                <div dangerouslySetInnerHTML={{ __html: homePageBnbContentCMS }} />
            ) : (
                <div dangerouslySetInnerHTML={{ __html: homePageContentCMS }} />
            )}
        </div>
    );
};
