import initialState, { GacOverAllInfo, GacSuggestionItem, GeoPath } from '../store/initialState';
import {
    GAC_SEARCH_RESULTS_READY,
    GAC_SUGGESTION_INPUT_ERROR,
    GAC_SUGGESTION_SELECTED,
    GAC_SET_REAL_NAMES,
    GAC_SET_SEO_LABEL,
} from '../actions/geosuggestion.types';
import { GacAction } from '../actions/geosuggestion';

export default (state = initialState.gacOverAllInfo, action: GacAction): GacOverAllInfo => {
    switch (action.type) {
        case GAC_SUGGESTION_SELECTED: {
            return {
                ...state,
                ...action.gacOverAllInfo,
            };
        }
        case GAC_SEARCH_RESULTS_READY: {
            return {
                ...state,
                suggestionGroups: action.gacOverAllInfo ? [...action.gacOverAllInfo.suggestionGroups] : [],
            };
        }
        case GAC_SUGGESTION_INPUT_ERROR: {
            return {
                ...state,
                ...action.gacOverAllInfo,
            };
        }
        case GAC_SET_REAL_NAMES: {
            return {
                ...state,
                selectedSuggestion: {
                    ...(state.selectedSuggestion as GacSuggestionItem),
                    geoPath: {
                        ...(state.selectedSuggestion?.geoPath as GeoPath),
                        realNames: action.names || [''],
                    },
                },
            };
        }
        case GAC_SET_SEO_LABEL: {
            return {
                ...state,
                selectedSuggestion: {
                    ...(state.selectedSuggestion as GacSuggestionItem),
                    seoLabel: action.seoLabel,
                },
            };
        }
        default:
            return state;
    }
};
