import React from 'react';
import styles from './tab.less';

type Props = {
    onSelectedTab: (tabId: string, path: string, pageTitle: string) => void;
    id: string;
    value: string;
    selectedTab: string;
    path: string;
    pageTitle: string;
};
const Tab: React.FC<Props> = ({ id, value, onSelectedTab, selectedTab, path, pageTitle }: Props) => {
    return (
        <div
            onClick={() => onSelectedTab(id, path, pageTitle)}
            id={id}
            className={`${styles.tabWrapper} ${selectedTab === id ? styles.active : ''}`}
        >
            <button
                className="tab"
                role="tab"
                aria-selected={selectedTab === id}
                tabIndex={0}
                aria-controls={`tabcontent-${id}`}
            >
                <span>{value}</span>
            </button>
        </div>
    );
};

export default Tab;
