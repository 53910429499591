import React from 'react';

type Props = {
    selectedTab: string;
    tabId: string;
    children?: React.ReactNode;
};

const TabContent: React.FC<Props> = ({ selectedTab, tabId, children }: Props) => {
    return (
        <div hidden={selectedTab !== tabId}>
            <div role="tabcontent" tabIndex={0} id={`tabcontent-${tabId}`} aria-labelledby={tabId}>
                {children}
            </div>
        </div>
    );
};

export default TabContent;
