import { connect } from 'react-redux';
import React from 'react';
import { bindActionCreators } from 'redux';
import { State as initialState } from '../../store/initialState';
import { HeroBlock } from './HeroBlock';
import * as actions from '../../actions/branchenbuchSearch';
import tabs from './tabNames';

export default connect(
    (state: initialState): Partial<React.ComponentProps<typeof HeroBlock>> => {
        return {
            apiEndPoint: state.config.API_ENDPOINT as string,
            selectedTab: state.branchenbuchSearch?.isBranchenbuchSearch ? tabs.TAB2 : tabs.TAB1,
        };
    },
    (dispatch) => bindActionCreators(actions, dispatch),
)(HeroBlock);
