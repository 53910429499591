import { SET_TRACKING_SEARCH_ID } from '../actions/tracking.types';
import initialState from '../store/initialState';
import State from '../store/initialState';
import { TrackingSearchIdAction } from '../actions/tracking';

export default (state = initialState.tracking, action: TrackingSearchIdAction): typeof State.tracking => {
    switch (action.type) {
        case SET_TRACKING_SEARCH_ID: {
            return {
                ...state,
                ...action.tracking,
            };
        }
        default:
            return state;
    }
};
