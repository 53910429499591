import React from 'react';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { GacSuggestionGroup, GacSuggestionItem } from '../../store/initialState';
import './GacInputField.less';
import { fetchSuggestions } from './GacUtils';
import { debounce } from '../../utils/debounce';

type Props = {
    selectedSuggestion?: GacSuggestionItem;
    onSuggestionSelected(SuggestionItem): void;
    onSetSuggestionInputError?: (error: boolean) => void;
    suggestionInputError?: boolean;
    keepFocus?: boolean;
    apiEndPoint: string | unknown;
    placeholder?: string;
    icon?: boolean;
    onFocus?: () => void;
    id: string;
    nextgenData?: boolean;
    className?: string;
    closeIcon?: boolean;
    locationIcon?: boolean;
    readonly?: boolean;
    hoverFocusDisabled?: boolean;
};

type State = {
    value: string;
    suggestions: Array<GacSuggestionGroup>;
    selectedSuggestion?: GacSuggestionItem;
    showCloseIcon: boolean;
};

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSectionTitle(section) {
    return regionTranslations[section.title];
}

function getSectionSuggestions(section) {
    return section.items;
}

const regionTranslations = {
    region: 'Bundesländer',
    city: 'Ort',
    quarter: 'Viertel',
    postcodeWithQuarter: 'PLZ',
    postcode: 'PLZ',
    district: 'Kreis',
    quarterOrTown: 'Bezirke und Gemeinden',
};

const ENTER_KEY = 13;

export class GacInputField extends React.Component<Props, State> {
    private readonly inputRef: React.RefObject<HTMLInputElement>;
    private readonly containerRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.selectedSuggestion?.name || '',
            suggestions: [],
            showCloseIcon: false,
        };
        // @ts-ignore
        this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 100);
        this.onChange = this.onChange.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.handleOnBodyClick = this.handleOnBodyClick.bind(this);
        this.hideClearIcon = this.hideClearIcon.bind(this);
        this.inputRef = React.createRef();
        this.containerRef = React.createRef();
    }

    componentDidMount(): void {
        this.props.keepFocus ? this.removeInputFocus() : this.addInputFocus();
        if (this.props.closeIcon) {
            const body = document.getElementsByTagName('body')[0];
            body.addEventListener('click', this.handleOnBodyClick);
        }
    }

    handleOnBodyClick(e: MouseEvent): void {
        if (!this.state.showCloseIcon || !e.target) {
            return;
        }

        if (!this.containerRef?.current?.contains(e.target as Node)) {
            this.setState({ showCloseIcon: false });
        }
    }

    onChange(event, { newValue }): void {
        this.setState({
            value: newValue,
        });
        if (this.props.onSetSuggestionInputError) {
            this.props.onSetSuggestionInputError(!newValue || newValue.trim().length === 0);
        }

        if (newValue && newValue.length > 0) {
            this.setState({ showCloseIcon: true });
        } else {
            this.setState({ showCloseIcon: false });
        }
    }

    focus(): void {
        this.inputRef?.current?.focus();
    }
    clearInput(): void {
        this.setState({ value: '' });
        setTimeout(() => this.addInputFocus(), 0);
    }

    onInputFocus(): void {
        this.setState({ suggestions: [] });
        this.hideClearIcon();
        this.props.onFocus && this.props.onFocus();
    }
    getValue(): string {
        return this.state.value;
    }

    hideClearIcon() {
        this.props.closeIcon && this.setState({ showCloseIcon: this.getValue()?.length > 0 });
    }

    onKeyDown(event): void {
        if (event.keyCode === ENTER_KEY) {
            event.preventDefault();
            if (!this.state.value && this.props.onSetSuggestionInputError) {
                this.props.onSetSuggestionInputError(true);
                return;
            }
        }
    }

    onSuggestionSelected(event, { suggestion }): void {
        this.props.onSuggestionSelected(suggestion);
        this.setState({ showCloseIcon: false });
    }

    async onSuggestionsFetchRequested({ value }): Promise<void> {
        const apiEndpoint = this.props.apiEndPoint;
        let url = `${apiEndpoint}/searchlocation?query=${encodeURIComponent(value)}`;
        if (!this.props.nextgenData) {
            url += `&nextgendata=${this.props.nextgenData}`;
        }
        const suggestions: Array<GacSuggestionGroup> = await fetchSuggestions(value, url);
        this.setState({ suggestions });
    }

    renderSuggestion(suggestion: GacSuggestionItem): React.ReactElement {
        const matches = match(suggestion.name, this.state.value.trim());
        const parts = parse(suggestion.name, matches);
        return (
            <span>
                {parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : '';

                    return (
                        <span className={className} key={index}>
                            {part.text}
                        </span>
                    );
                })}
            </span>
        );
    }
    renderInputComponent(inputProps): React.ReactElement {
        return (
            <div className={`grid-item input-text-container`}>
                {this.props.locationIcon ? (
                    <span className="input-icon-left absolute-content is24-icon-location" />
                ) : (
                    this.props.icon && <span className="input-icon-left absolute-content is24-icon-search" />
                )}
                {this.props.closeIcon && this.state.showCloseIcon && (
                    <div onClick={this.clearInput}>
                        <span className="input-icon-right absolute-content cursor-pointer is24-icon-closing closeIcon" />
                    </div>
                )}
                <input
                    id="search-input-makler"
                    {...inputProps}
                    ref={this.inputRef}
                    readOnly={this.props.readonly ? true : false}
                />
            </div>
        );
    }

    removeInputFocus(): void {
        this.inputRef.current?.blur();
    }

    addInputFocus(): void {
        this.inputRef.current?.focus();
    }

    render(): React.ReactElement {
        const { value, suggestions } = this.state;
        const props = {
            placeholder: this.props.placeholder || 'Ort order PLZ',
            onChange: this.onChange,
            onFocus: this.onInputFocus,
            className: `input-text one-whole font-s ${this.props.suggestionInputError && 'border border-error'} ${
                this.props.hoverFocusDisabled && 'disableHoverFocus'
            }`,
            value: value,
            onKeyDown: this.onKeyDown,
        };
        return (
            <div id={this.props.id} ref={this.containerRef} className={this.props.className}>
                <Autosuggest
                    multiSection
                    highlightFirstSuggestion
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    renderSuggestion={this.renderSuggestion}
                    renderSectionTitle={renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionsClearRequested={() => 0}
                    getSuggestionValue={getSuggestionValue}
                    renderInputComponent={this.renderInputComponent}
                    inputProps={props}
                    focusInputOnSuggestionClick={false}
                />
            </div>
        );
    }
}
