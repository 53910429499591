import React, { useState } from 'react';
import fetch from 'node-fetch';

import styles from './Hero.less';
import './Hero.less';
import { trackEvent } from '../../utils/tracking';
import GacContainer from '../gaccontainer/GacContainer';
import { generateSearchId } from '../../utils/searchId';
import { GacSuggestionItem } from '../../store/initialState';
import TabContainer from '../common/tabcontainer/TabContainer';
import Tab from '../common/tabcontainer/Tab';
import TabContent from '../common/tabcontainer/TabContent';
import { changeUrl, paths, pageTitles } from '../../utils/changeUrl';
import tabs from './tabNames';
import { realtorCategories } from '../realtorCategoryDropdown/CategoryDropdown';

type HeroBlockProps = {
    apiEndPoint: string;
    selectedTab: string;
    setBranchenbuchSearch: (isBranchenbuchSearch: boolean) => void;
};
type BranchenBuchRequest = {
    cityOrPostCode: string;
    geoCodeId?: string | null;
    searchType: string;
    gacId?: string | null;
};

export const HeroBlock: React.FC<HeroBlockProps> = ({
    apiEndPoint,
    selectedTab,
    setBranchenbuchSearch,
}: HeroBlockProps): JSX.Element => {
    const [selectAgentLocation, setAgentLocationSuggestion] = useState<GacSuggestionItem>();
    const [selectAgencyLocation, setAgencyLocationSuggestion] = useState<GacSuggestionItem>();
    const [selectedRealtorCategory, setSelectedRealtorCategory] = useState(realtorCategories[0]);

    React.useEffect(() => {
        window.onpopstate = () => {
            setBranchenbuchSearch(selectedTab !== tabs.TAB2);
        };
    });

    const onTabSwitch = (tabId, path, pageTitle): void => {
        if (tabId === tabs.TAB1) {
            setBranchenbuchSearch(false);
        } else setBranchenbuchSearch(true);
        changeUrl(window.location.origin, path, pageTitle);
    };

    const redirectToSearchPage = () => {
        if (!selectAgentLocation || selectAgentLocation.geoPath.uri === '') return;

        const searchId = generateSearchId();
        trackEvent({
            label: `homepage_execute_agent_search_${searchId}`,
            name: `homepage_search_execute`,
            parameter_1: `searchId_${searchId}`,
        });
        window.location.href = `/anbieter/immobilienmakler${selectAgentLocation.geoPath.uri}?searchid=${searchId}`;
    };

    const redirectToBranchenbuchPage = async () => {
        if (!selectAgencyLocation || selectAgencyLocation.geoPath.uri === '') return;

        let geoInfoData: BranchenBuchRequest = {
            cityOrPostCode: selectAgencyLocation.name,
            geoCodeId: selectAgencyLocation.value,
            searchType: selectedRealtorCategory.value,
            gacId: null,
        };

        if (selectAgencyLocation.type === 'postcode') {
            geoInfoData = {
                ...geoInfoData,
                gacId: selectAgencyLocation.value,
                geoCodeId: null,
            };
        }
        const response = await fetch(`https://${window.location.host}/anbieter/rest`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(geoInfoData),
        });
        const data = await response.json();

        window.location.href = data.searchUrl;
    };

    const sellingPointsAgentSearch = [
        'Seriöse und kompetente Makler',
        'Bewertet von Eigentümern und Suchenden',
        'Direkt kontaktieren',
    ];
    const sellingPointsRealtorDirectory = [
        'Größtes Firmennetzwerk Deutschlands',
        'Von Baufinanzierung bis Umzug',
        'Kostenlos kontaktieren',
    ];

    return (
        <div className={`one-whole horizontal-center ${styles.heroWrapper}`}>
            <div
                className={`six-twelfth lap-one-whole palm-one-whole horizontal-center padding-l ${styles.contentWrapper}`}
            >
                <div className={`${styles.headline} font-xxl font-line-s font-white font-bold`}>
                    {selectedTab === 'realtor_directory' ? 'Finden Sie Profis' : 'Makler gesucht?'}
                </div>
                <div className={`font-xxl font-line-s font-white font-bold`}>
                    {selectedTab === 'realtor_directory' ? 'rund um Immobilien' : 'Los geht’s.'}
                </div>
                <div className={styles.searchBox}>
                    <TabContainer>
                        <Tab
                            id={tabs.TAB1}
                            value="Makler"
                            selectedTab={selectedTab}
                            path={paths.AGENT_SEARCH}
                            pageTitle={pageTitles.AGENT_SEARCH}
                            onSelectedTab={onTabSwitch}
                        />
                        <Tab
                            id={tabs.TAB2}
                            value="Branchenbuch"
                            selectedTab={selectedTab}
                            path={paths.BRANCHENBUCH}
                            pageTitle={pageTitles.BRANCHENBUCH}
                            onSelectedTab={onTabSwitch}
                        />
                    </TabContainer>
                    <TabContent selectedTab={selectedTab} tabId={tabs.TAB1}>
                        <GacContainer
                            apiEndPoint={apiEndPoint}
                            placeholder="Wo suchen Sie?"
                            buttonText="Makler finden"
                            id="home-page-agent-search"
                            onSuggestionSelected={setAgentLocationSuggestion}
                            onButtonClick={redirectToSearchPage}
                            isBranchenbuchSearch={false}
                            onCategorySelected={setSelectedRealtorCategory}
                            selectedCategory={selectedRealtorCategory}
                        />
                    </TabContent>
                    <TabContent selectedTab={selectedTab} tabId={tabs.TAB2}>
                        <GacContainer
                            apiEndPoint={apiEndPoint}
                            placeholder="Wo suchen Sie?"
                            buttonText="Anbieter finden"
                            id="home-page-realtor-directory"
                            onSuggestionSelected={setAgencyLocationSuggestion}
                            onButtonClick={redirectToBranchenbuchPage}
                            nextgenData={false}
                            isBranchenbuchSearch={true}
                            onCategorySelected={setSelectedRealtorCategory}
                            selectedCategory={selectedRealtorCategory}
                        />
                    </TabContent>
                </div>
            </div>
            <div className={`one-whole align-center ${styles.upsBlock}`} data-test-id="ups-block">
                {selectedTab === tabs.TAB1
                    ? sellingPointsAgentSearch.map((item, index) => (
                          <span key={index}>
                              <i className="is24-icon-positive-check" aria-hidden={true} />
                              {item}
                          </span>
                      ))
                    : sellingPointsRealtorDirectory.map((item, index) => (
                          <span key={index}>
                              <i className="is24-icon-positive-check" aria-hidden={true} />
                              {item}
                          </span>
                      ))}
            </div>
        </div>
    );
};
