import { Action } from 'redux';
import { BranchenbuchSearch } from '../store/initialState';
import { SET_BRANCHENBUCH_SEARCH } from './branchenbuchSearch.types';

export type BranchenbuchSearchAction = Action<string | null> & {
    branchenbuchSearch: BranchenbuchSearch;
};

export const setBranchenbuchSearch = (isBranchenbuchSearch: boolean): BranchenbuchSearchAction => {
    return {
        type: SET_BRANCHENBUCH_SEARCH,
        branchenbuchSearch: { isBranchenbuchSearch },
    };
};
