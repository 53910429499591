import { connect } from 'react-redux';
import React from 'react';
import { bindActionCreators } from 'redux';
import { State as initialState } from '../../store/initialState';
import * as actions from '../../actions/branchenbuchSearch';
import { HomePageContents } from './HomePageContents';

export default connect(
    (state: initialState): Partial<React.ComponentProps<typeof HomePageContents>> => {
        return {
            isBranchenbuch: state.branchenbuchSearch?.isBranchenbuchSearch,
        };
    },
    (dispatch) => bindActionCreators(actions, dispatch),
)(HomePageContents);
