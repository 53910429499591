import {
    SEARCH_FAILED,
    SEARCH_INITIATED,
    SET_SEARCH_RESULTS,
    UPDATE_CURRENT_PAGE,
    UPDATE_SORT_ORDER,
    SET_NEARBY_AGENTS,
} from '../actions/search.types';
import { SearchAction } from '../actions/search';
import initialState, { Search } from '../store/initialState';

export default (state = initialState.search, action: SearchAction): Search => {
    switch (action.type) {
        case SEARCH_INITIATED: {
            return {
                ...state,
                ...action.search,
                searchInProgress: true,
            };
        }
        case SET_SEARCH_RESULTS: {
            return {
                ...state,
                ...action.search,
                searchInProgress: false,
            };
        }
        case SEARCH_FAILED: {
            return {
                ...state,
                pageResults: [],
                searchFailed: action.searchFailed,
                searchInProgress: false,
            };
        }
        case UPDATE_CURRENT_PAGE: {
            return {
                ...state,
                ...action.search,
                searchInProgress: false,
                pageResults: [],
            };
        }
        case UPDATE_SORT_ORDER: {
            return {
                ...state,
                ...action.search,
            };
        }
        case SET_NEARBY_AGENTS: {
            return {
                ...state,
                ...action.search,
                searchInProgress: false,
            };
        }

        default:
            return state;
    }
};
