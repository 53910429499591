import { trackEvent } from './tracking';

export const changeUrl = (origin: string, destination: string, pageTitle: string): void => {
    const newUrl = `${origin}/${destination}`;
    window.history.pushState({}, 'agent_web_search', newUrl);
    document.title = pageTitle;
    if (destination === paths.BRANCHENBUCH) {
        window['IS24'].TEALIUM.tracking.updateCommonUtagData({ pag_pagetitle: 'anbieter.index' });
        trackEvent({
            label: `homepage_switch_to_realtor_directory_tab`,
            name: 'homepage_to_realtor_directory_tab_switch',
        });
    }
    if (destination === paths.AGENT_SEARCH) {
        window['IS24'].TEALIUM.tracking.updateCommonUtagData({ pag_pagetitle: 'anbieter.agent-search.home' });
        trackEvent({ label: `homepage_switch_to_agent_search_tab`, name: 'homepage_to_agent_search_tab_switch' });
    }
};

export const paths = {
    BRANCHENBUCH: 'anbieter/',
    AGENT_SEARCH: 'anbieter/immobilienmakler',
};

export const pageTitles = {
    BRANCHENBUCH: 'Immobilien-Branchenbuch: Makler, Bauträger, Verwalter finden',
    AGENT_SEARCH: 'Immobilienmakler gesucht? Makler finden bei ImmoScout24',
};
