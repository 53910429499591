import { SET_SEO_DATA } from '../actions/seodata.types';
import initialState from '../store/initialState';
import State from '../store/initialState';
import { SeoDataAction } from '../actions/seodata';

export default (state = initialState.seoData, action: SeoDataAction): typeof State.seoData => {
    switch (action.type) {
        case SET_SEO_DATA: {
            return {
                ...state,
                ...action.seoData,
            };
        }
        default:
            return state;
    }
};
