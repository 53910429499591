import fetch from 'node-fetch';
import { groupBy } from '../../utils/groupBy';
import { GacSuggestionGroup, GacSuggestionItem } from '../../store/initialState';
import { RawGacSuggestionResult } from '../../models/RawGacSuggestionResult';

export const fetchSuggestions = async (searchInput: string, url: string): Promise<Array<GacSuggestionGroup>> => {
    const response = await fetch(url, { method: 'GET' });
    if (!response.ok) {
        return [];
    }
    const rawSuggestionsData = await response.json();
    const mappedData = mapRawData(rawSuggestionsData?.data ? rawSuggestionsData.data : []);
    return orderData(mappedData);
};

export const mapRawData = (data: Array<RawGacSuggestionResult>): Array<GacSuggestionGroup> => {
    const groupedData = groupBy(data, (rawSuggestion: RawGacSuggestionResult) => rawSuggestion.entity.type);
    const suggestions: Array<GacSuggestionGroup> = [];
    for (const key in groupedData) {
        if (Object.hasOwnProperty.call(groupedData, key)) {
            suggestions.push({
                title: key,
                items: mapRawSuggestions(groupedData[key]),
            });
        }
    }
    return suggestions;
};

const mapRawSuggestions = (rawSuggestions: Array<RawGacSuggestionResult>): Array<GacSuggestionItem> => {
    return rawSuggestions.map((suggestion) => ({
        name: suggestion.entity.label,
        value: suggestion.entity.id,
        geoPath: suggestion.entity.geopath,
        type: suggestion.entity.type,
    }));
};

export const orderData = (array: Array<GacSuggestionGroup>): Array<GacSuggestionGroup> => {
    const levels = ['region', 'city', 'district', 'quarterOrTown', 'quarter', 'postcode'];
    const ordered: Array<GacSuggestionGroup> = [];
    levels.map((level) => {
        ordered.push(array.filter((item) => item.title === level)[0]);
    });
    return ordered.filter(Boolean);
};
