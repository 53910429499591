import { applyMiddleware, compose, createStore, Middleware, StoreCreator } from 'redux';
import thunkMw from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducerWithoutRouting from '../reducers/root';
import { isRenderedOnServer } from '../utils/platform';
import { State } from './initialState';

type StoreSetup = {
    routerMw: Middleware;
    rootReducer: any;
};
function createStoreSetup(): StoreSetup {
    const history = createBrowserHistory();
    const routerMw = routerMiddleware(history);
    const connectRouterHistory = connectRouter(history);
    const rootReducer = connectRouterHistory(rootReducerWithoutRouting);

    return { routerMw, rootReducer };
}

function createServerStore(initialState: Partial<State>): StoreCreator {
    return createStore(rootReducerWithoutRouting, initialState);
}

export const configureStoreProd = (initialState: Partial<State>): any => {
    if (isRenderedOnServer()) {
        return createServerStore(initialState);
    }

    const { routerMw, rootReducer } = createStoreSetup();
    const middlewares = [thunkMw, routerMw];

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)));
};

export const configureStoreDev = (initialState: Partial<State>): any => {
    if (isRenderedOnServer()) {
        return createServerStore(initialState);
    }

    const { routerMw, rootReducer } = createStoreSetup();

    const middlewares = [thunkMw, routerMw];
    const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

const configureStore = (initialState: Partial<State>) =>
    initialState.config?.NODE_ENV === 'production' ? configureStoreProd(initialState) : configureStoreDev(initialState);

export default configureStore;
