import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import HomeLayout from '../layouts/home/Home';
import configureStore from '../store/configureStore';

const { config, branchenbuchSearch, teaserData } = window['appModel'];
const store = configureStore({
    config: config,
    branchenbuchSearch: branchenbuchSearch,
    teaserData: teaserData,
});
ReactDOM.hydrate(
    <Provider store={store}>
        <HomeLayout />
    </Provider>,
    document.getElementById('app'),
);
